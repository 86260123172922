import React, { Fragment } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import _map from 'lodash/map';
import Img from 'gatsby-image';
import SEO from '../components/SEO';
import ContactForm from '../components/ContactForm/ContactForm';
import {
  PageWrapper,
  PageInner,
  PageTitle,
  PostLink,
  Banner,
  Grid2Col,
  Grid3Col,
  LinkToGoogleMap,
  NavigationBottom,
  ArrowUpIcon,
  ArrowUpLink,
  Spacer,

} from '../components/Elements';

const contactQuery = graphql`
  {
    banner: file(relativePath: { eq: "bandeau_contact.png" }) {
        childImageSharp {
            fluid( maxWidth:1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }

    plan: file(relativePath: { eq: "plan_acces.png" }) {
        childImageSharp {
            fixed(quality:100,  width:600) {
            ...GatsbyImageSharpFixed
          }
          fluid( maxWidth:1920) {
            ...GatsbyImageSharpFluid
          }
        }
    }

    page: datoCmsPageContact {
      titre
      contenu
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`;

export default function ContactPage() {
  const data = useStaticQuery(contactQuery);
  const { titre, contenu, seoMetaTags } = data.page;

  return (
    <Fragment>
      <SEO meta={seoMetaTags} />
      <PageWrapper>
       <Banner height="75px">
          <Img fluid={data.banner.childImageSharp.fluid} />
        </Banner>
        <PageInner>
        <PageTitle>{titre}</PageTitle>
        <Grid3Col>

          <div>
             
              <div dangerouslySetInnerHTML={{ __html: contenu }} />
              <Spacer/>
          </div>
             
          <div> 
            <Img fluid={data.plan.childImageSharp.fluid}/>
            <LinkToGoogleMap href="https://www.google.com/maps/dir//''/@44.7362325,4.908532,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x12b55964c2175e2d:0x12ae665efa968efe!2m2!1d4.9787764!2d44.7362389" target="_blank">Calculez votre itinéraire</LinkToGoogleMap>
             <Spacer/>          
          </div>
           
          <ContactForm/> 
         

          </Grid3Col>
           <Spacer/>
      {/*  <NavigationBottom>
          <ArrowUpLink to="/ressources" title="Ressources">Ressources<ArrowUpIcon title="Aller à la page Ressources" />
          </ArrowUpLink>
        </NavigationBottom>*/}
        </PageInner>
      </PageWrapper>
    </Fragment>
  );
}
