import React from 'react'
import styles from './ContactForm.module.scss'

const ContactForm = ({invisible=false}) => 
(
	
		<form 
			name="Formulaire de contact" 
			action="/succes" 
			method="POST" 
			data-netlify="true" 
			netlify-honeypot="bot-field" 
		//	className={"btn-group pull-right " + (this.props.showBulkActions ? 'show' : 'hidden')}
			style={ invisible ? { position:'absolute',height:0,width:0,overflow:'hidden',visibility:'hidden',left:'-10000px'} : null }
		>
		   
		   
		     	<input type="hidden" name="bot-field" />
		    	<input type="hidden" name="form-name" value="Formulaire de contact" />
			   
				<label htmlFor="name">Nom Prénom</label>
		    	<input placeholder="Nom Prénom" type="text" name="name" id="name" required  />
			            
			        
			    <label htmlFor="email">Email</label>
			    <input placeholder="Email" type="text" name="email" id="email" required  />
			           
			    <label htmlFor="telephone">Téléphone</label>
		    	<input placeholder="Téléphone" type="text" name="telephone" id="telephone" required  />
			    
		        <label htmlFor="message">Message</label>
		        <textarea name="message" id="message" rows="6" required ></textarea>
			  
			    <div className="actions">
			   		<input type="submit" value="Envoyer" className="special" />
			    </div>
			    <p>Note : Les données collectées via ce formulaire ont pour unique finalité de nous permettre de répondre à votre demande.</p>
		</form>
)

export default ContactForm